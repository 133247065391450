import * as React from "react";
import { useEffect, useRef, useState, useCallback } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import ToggleButton from "@mui/material/ToggleButton";
import Typography from "@mui/material/Typography";

import { model } from "@volley/physics";
import {
    BboxImage,
    FramePrimitive,
    People,
    PointQuad,
    Track,
} from "@volley/shared/vision-models";

import position2Grid from "../Admin/Clips/grid";
import {
    drawBallTrack,
    drawCourtCornersImageSpace,
    drawFilterImageSpace,
    drawFrameId,
    drawLegend,
    drawPerson,
    drawPersonAoi,
    drawServeData,
    drawPersonHighlight,
    getCourtDrawingFunctionsForSport,
} from "../Admin/Clips/primitiveDrawing";
import { useCurrentUser } from "../hooks/currentUser";

interface Props {
    videoUrl: string;
    posterUrl?: string;
    primitives: FramePrimitive[];
}

const STROKE_LIFESPAN = 15;

export default function ClipVideoOnCanvas({
    videoUrl,
    posterUrl,
    primitives,
}: Props) {
    const { isAdmin } = useCurrentUser();
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const courtCanvasRef = useRef<HTMLCanvasElement>(null);
    const videoRef = useRef<HTMLVideoElement>(null);
    const callbackHandle = useRef<number | null>(null);
    const [frameNumber, setFrameNumber] = useState(0);
    const [scaleFactor, setScaleFactor] = useState(1.0);
    const [resizeTimestamp, setResizeTimestamp] = React.useState(Date.now());
    const [drawBox, setDrawBox] = useState(false);
    const [drawPose, setDrawPose] = useState(false);
    const [drawServe, setDrawServe] = useState(false);
    const [drawBall, setDrawBall] = useState(false);
    const [dimVideo, setDimVideo] = useState(false);
    const [showGrid, setShowGrid] = useState(false);
    const [gridSize, setGridSize] = useState([0, 0]);
    const [courtSport, setCourtSport] = useState<model.Sport>(model.Sports[0]);
    const [physicsModel, setPhysicsModel] = useState<model.PhysicsModelName>(
        model.ValidPhysicsModelNames[0],
    );
    const [sport, setSport] = useState<model.Sport>(model.Sports[0]);
    const [gridPosition, setGridPosition] = React.useState("C ? : R ?");

    const [lastPersons, setLastPersons] = React.useState<People[]>([]);
    const [lastTracks, setLastTracks] = React.useState<Track[]>([]);
    const [lastPersonAoi, setLastPersonAoi] = React.useState<BboxImage | null>(
        null,
    );
    const [lastFilterImageSpace, setLastFilterImageSpace] =
        React.useState<PointQuad | null>();
    const [lastCourtCornersImageSpace, setLastCourtCornersImageSpace] =
        React.useState<PointQuad | null>();

    const [activeStrokes, setActiveStrokes] = useState<
        { person: People; lifespan: number }[]
    >([]);

    const videoCallback = useCallback(
        (_: DOMHighResTimeStamp, metadata: VideoFrameCallbackMetadata) => {
            // set the frame number based on current time and expected frame rate of 30
            const currentFrameNumber = Math.round(metadata.mediaTime * 30);
            setFrameNumber(currentFrameNumber);
            const video = videoRef.current;
            if (video) {
                callbackHandle.current =
                    video.requestVideoFrameCallback(videoCallback);
            }
        },
        [],
    );

    useEffect(() => {
        if (!primitives || !canvasRef.current) return;

        const framePrimitive = primitives[frameNumber];
        if (!framePrimitive) return;

        // Update active events
        setActiveStrokes((prevEvents) => {
            // Decrement lifespan and filter out expired events
            const updatedEvents = prevEvents
                .map((e) => ({ ...e, lifespan: e.lifespan - 1 }))
                .filter((e) => e.lifespan > 0);

            if (framePrimitive.primitives?.people) {
                framePrimitive.primitives.people.forEach((person) => {
                    const existingStroke = updatedEvents.find(
                        (e) => e.person.id === person.id,
                    );
                    if (!existingStroke && person.stroke) {
                        updatedEvents.push({
                            person: person,
                            lifespan: STROKE_LIFESPAN,
                        });
                    }
                });
            }
            return updatedEvents;
        });
    }, [frameNumber, primitives]);

    useEffect(() => {
        try {
            const video = videoRef.current;
            const canvas = canvasRef.current;
            const courtCanvas = courtCanvasRef.current;

            if (!primitives || !canvas || !video || !courtCanvas) {
                return;
            }

            const context = canvas.getContext("2d");
            const courtContext = courtCanvas.getContext("2d");
            if (!context || !courtContext) {
                return;
            }

            const framePrimitive = primitives[frameNumber];
            if (!framePrimitive) {
                return;
            }

            if (
                framePrimitive.primitives?.people &&
                framePrimitive.primitives.people.length > 0
            ) {
                setLastPersons(framePrimitive.primitives.people);
            }

            if (
                framePrimitive.primitives?.tracks &&
                framePrimitive.primitives.tracks.length > 0
            ) {
                setLastTracks(framePrimitive.primitives.tracks);
            }

            const {
                drawCourt,
                drawPersonCourt,
                drawCameraCourt,
                drawBallTrackCourt,
            } = getCourtDrawingFunctionsForSport(courtSport);

            context.clearRect(0, 0, canvas.width, canvas.height);
            courtContext.clearRect(0, 0, courtCanvas.width, courtCanvas.height);
            drawCourt(courtContext);

            context.clearRect(0, 0, canvas.width, canvas.height);
            courtContext.clearRect(0, 0, courtCanvas.width, courtCanvas.height);
            drawCourt(courtContext);

            drawFrameId(
                context,
                framePrimitive.frameId ?? -1,
                framePrimitive.timestampMs ?? -1,
                scaleFactor,
            );

            if (!framePrimitive) {
                return;
            }

            const { filterImageSpace, courtCornersImageSpace, personAoi } =
                framePrimitive;

            if (filterImageSpace) {
                setLastFilterImageSpace(filterImageSpace);
            }

            if (courtCornersImageSpace) {
                setLastCourtCornersImageSpace(courtCornersImageSpace);
            }

            if (personAoi) {
                setLastPersonAoi(personAoi);
            }

            if ((drawBox || drawPose) && lastFilterImageSpace) {
                drawFilterImageSpace(
                    context,
                    lastFilterImageSpace,
                    scaleFactor,
                );
            }

            if ((drawBox || drawPose) && lastCourtCornersImageSpace) {
                drawCourtCornersImageSpace(
                    context,
                    lastCourtCornersImageSpace,
                    scaleFactor,
                );
            }

            if ((drawBox || drawPose) && lastPersonAoi) {
                drawPersonAoi(context, lastPersonAoi, scaleFactor);
            }

            lastPersons.forEach((person) => {
                drawPerson(context, person, scaleFactor, drawBox, drawPose);
                drawPersonCourt(courtContext, person);
                if (drawServe) {
                    drawServeData(context, person, scaleFactor);

                    if (activeStrokes.some((e) => e.person.id === person.id)) {
                        drawPersonHighlight(context, person, scaleFactor);
                    }
                }
            });

            if (framePrimitive.cameraPosition) {
                drawCameraCourt(courtContext, framePrimitive.cameraPosition);
            }

            if (drawBall) {
                lastTracks.forEach((ballTrack) => {
                    drawBallTrack(context, ballTrack, scaleFactor);
                    drawBallTrackCourt(courtContext, ballTrack);
                });

                drawLegend(context, lastTracks, canvas.width, scaleFactor);
            }

            if (showGrid && gridSize[0] > 0 && gridSize[1] > 0) {
                lastPersons.forEach((person) => {
                    const grid = position2Grid(
                        person.location,
                        gridSize[0],
                        gridSize[1],
                        physicsModel,
                        sport,
                    );
                    setGridPosition(`C ${grid[0]} : R ${grid[1]}`);
                });
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error("Error in drawing function:", error);
        }
    }, [
        frameNumber,
        primitives,
        scaleFactor,
        drawBox,
        drawBall,
        drawPose,
        resizeTimestamp,
        lastPersons,
        lastTracks,
        gridSize,
        physicsModel,
        sport,
        showGrid,
        courtSport,
        drawServe,
        activeStrokes,
        lastFilterImageSpace,
        lastCourtCornersImageSpace,
        lastPersonAoi,
    ]);

    const onLoadedData = useCallback(() => {
        const video = videoRef.current;
        const canvas = canvasRef.current;
        const courtCanvas = courtCanvasRef.current;
        const context = canvas?.getContext("2d");
        if (!video || !canvas || !context || !courtCanvas) {
            return;
        }

        // Cancel any frame callbacks
        if (callbackHandle.current) {
            video.cancelVideoFrameCallback(callbackHandle.current);
        }

        const videoRect = video.getBoundingClientRect();
        setScaleFactor(videoRect.width / video.videoWidth);
        canvas.height = videoRect.height;
        canvas.width = videoRect.width;
        canvas.style.width = `${videoRect.width}px`;
        canvas.style.height = `${videoRect.height}px`;

        courtCanvas.height = 600;
        courtCanvas.width = 300;
        courtCanvas.style.height = `${600}px`;
        courtCanvas.style.width = `${300}px`;
        callbackHandle.current = video.requestVideoFrameCallback(videoCallback);
    }, [videoRef, videoCallback]);

    const firstLoad = React.useRef(true);

    React.useEffect(() => {
        const video = videoRef.current;

        if (firstLoad.current) {
            firstLoad.current = false;
        } else {
            videoRef.current?.load();
            setFrameNumber(0);
        }

        return () => {
            if (video && callbackHandle.current) {
                video.cancelVideoFrameCallback(callbackHandle.current);
            }
        };
    }, [videoUrl]);

    React.useEffect(() => {
        function onResize() {
            onLoadedData();
            setResizeTimestamp(Date.now());
        }
        window.addEventListener("resize", onResize);
        return () => window.removeEventListener("resize", onResize);
    }, [onLoadedData]);

    return (
        <>
            <Stack direction="row" spacing={2} px={1}>
                <ToggleButton
                    value="show-boxes"
                    onChange={() => setDrawBox(!drawBox)}
                    selected={drawBox}
                    size="small"
                    color="info"
                >
                    Show Tracking
                </ToggleButton>
                <ToggleButton
                    value="show-boxes"
                    onChange={() => setDrawPose(!drawPose)}
                    selected={drawPose}
                    size="small"
                    color="info"
                >
                    Show Skeleton
                </ToggleButton>
                <ToggleButton
                    value="show-serve-data"
                    onChange={() => setDrawServe(!drawServe)}
                    selected={drawServe}
                    size="small"
                    color="info"
                >
                    Show Serve Data
                </ToggleButton>
                {isAdmin() && (
                    <ToggleButton
                        value="show-balls"
                        onChange={() => setDrawBall(!drawBall)}
                        selected={drawBall}
                        size="small"
                        color="info"
                    >
                        Show Ball
                    </ToggleButton>
                )}
                <ToggleButton
                    value="dim-video"
                    onChange={() => setDimVideo(!dimVideo)}
                    selected={dimVideo}
                    size="small"
                    color="info"
                >
                    Dim Video
                </ToggleButton>
                <ToggleButton
                    value="show-grid"
                    onChange={() => {
                        if (showGrid) {
                            setGridSize([0, 0]);
                        }
                        setShowGrid(!showGrid);
                    }}
                    selected={showGrid}
                    size="small"
                    color="info"
                >
                    Show Grid Position
                </ToggleButton>
                {showGrid && (
                    <Typography>{`Grid Position: ${gridPosition}`}</Typography>
                )}
            </Stack>

            <Box component="div" position="relative" mx={1}>
                <Box
                    sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: 10,
                        pointerEvents: "none",
                    }}
                    component="canvas"
                    ref={canvasRef}
                    className="canvas"
                />
                <video
                    ref={videoRef}
                    width="100%"
                    height=""
                    autoPlay
                    loop
                    muted
                    controls
                    onLoadedData={onLoadedData}
                    // The `playsInline` attribute forces Safari to not fullscreen auto-playing videos like this
                    // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/video#playsinline
                    playsInline
                    style={{
                        pointerEvents: "auto",
                        aspectRatio: "16 / 9",
                        width: "100%",
                        filter: dimVideo ? "brightness(0.25)" : "none",
                    }}
                    poster={posterUrl}
                >
                    <source src={videoUrl} type="video/mp4" />
                </video>
            </Box>
            <Stack>
                <FormControl sx={{ mb: 2 }}>
                    <InputLabel id="court-sport-label">Sport</InputLabel>
                    <Select
                        labelId="court-sport-label"
                        id="court-sport"
                        value={courtSport}
                        label="Court Sport"
                        onChange={(e) =>
                            setCourtSport(e.target.value as model.Sport)
                        }
                    >
                        {model.Sports.map((s) => (
                            <MenuItem key={s} value={s}>
                                {s}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Box
                    component="div"
                    position="relative"
                    mx={1}
                    width={300}
                    height={600}
                >
                    <Box
                        sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            zIndex: 10,
                            pointerEvents: "none",
                        }}
                        component="canvas"
                        ref={courtCanvasRef}
                        className="canvas"
                    />
                </Box>
            </Stack>
            <Dialog
                open={showGrid && gridSize[0] === 0 && gridSize[1] === 0}
                onClose={() => setShowGrid(false)}
                PaperProps={{
                    component: "form",
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const gridX = parseInt(
                            formData.get("gridX")?.toString() ?? "0",
                            10,
                        );
                        const gridY = parseInt(
                            formData.get("gridY")?.toString() ?? "0",
                            10,
                        );
                        setGridSize([gridX, gridY]);
                    },
                }}
            >
                <DialogTitle>Set Grid Size</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        To view grid position, specify the grid size, physics
                        model and sport.
                    </DialogContentText>
                    <Stack spacing={2}>
                        <Stack direction="row" spacing={2}>
                            <TextField
                                autoFocus
                                required
                                margin="dense"
                                id="gridX"
                                name="gridX"
                                label="Grid X Size"
                                type="number"
                                variant="standard"
                            />
                            <TextField
                                autoFocus
                                required
                                margin="dense"
                                id="gridY"
                                name="gridY"
                                label="Grid Y Size"
                                type="number"
                                variant="standard"
                            />
                        </Stack>
                        <FormControl fullWidth>
                            <InputLabel id="sport-label">Sport</InputLabel>
                            <Select
                                labelId="sport-label"
                                id="sport"
                                value={sport}
                                label="Sport"
                                onChange={(e) =>
                                    setSport(e.target.value as model.Sport)
                                }
                            >
                                {model.Sports.map((s) => (
                                    <MenuItem key={s} value={s}>
                                        {s}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel id="physics-label">
                                Physics Model
                            </InputLabel>
                            <Select
                                labelId="physics-label"
                                id="physics"
                                value={physicsModel}
                                label="Physics Model"
                                onChange={(e) =>
                                    setPhysicsModel(
                                        e.target
                                            .value as model.PhysicsModelName,
                                    )
                                }
                            >
                                {model.ValidPhysicsModelNames.map((p) => (
                                    <MenuItem key={p} value={p}>
                                        {p}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setGridSize([0, 0]);
                            setShowGrid(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button type="submit">Set Grid Size</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
